<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16 flexV">
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="收票开始日期"
          end-placeholder="收票结束日期"
        />
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange2"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="付款开始日期"
          end-placeholder="付款结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getOinvsList()"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新 </el-button>
        <el-button type="danger" size="small" class="vd_export" @click="backInfo()"> 退回发票 </el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'oinv_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getOinvsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch } from '@api/request';
import pubPagination from '@/components/common/pubPagination';
import { tableProperties } from '@/views/SettleManagement/OinvShaerManage/oinvshare';
import SearchTable from '@/components/table/SearchTableV2.vue';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import UrlEncode from '@assets/js/UrlEncode';
import { objectArrayReduce } from '../../../assets/js/arrayUtils';
import { oinvshareAPI } from '@api/modules/oinvshare';

export default {
  name: 'OinvList',
  components: {
    DynamicUTable,
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      tableProperties: tableProperties,
      tableData: [],
      multiSelection: [],
      totalPage: 0,
      btn: {},
      loadFlag: true
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/oinv_edit' || from.path === '/oinv_add') {
        this.initData();
      }
    }
  },
  methods: {
    objectArrayReduce,
    initData() {
      this.getOinvsList();
    },
    async getOinvsList() {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      const convertDateRange = range => {
        let [start, end] = range || [];
        start = start ? start / 1000 : null;
        end = end ? end / 1000 : null;
        return [start, end];
      };
      [searchForm.start_oinv_recdate, searchForm.end_oinv_recdate] = convertDateRange(this.dateRange);
      [searchForm.start_oinv_paydate, searchForm.end_oinv_paydate] = convertDateRange(this.dateRange2);
      try {
        const { data } = await getNoCatch(oinvshareAPI.getOinvShareList, searchForm);
        this.loading = false;
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadFlag = false;
      }
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/oinvshare_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.oinvshare_id
          })
        )
      });
    },
    refresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.getOinvsList();
    },
    backInfo() {
      if (this.multiSelection.length === 0) return this.$message.error('请至少选择一条数据！');
      if (this.multiSelection.length > 1) return this.$message.error('只可选择一条数据！');
      this.$confirm('确认退回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.multiSelection[0].finance_user_name = this.$cookies.get('userInfo').stff_name;
          oinvshareAPI.backOinv(this.multiSelection[0]).then(data => {
            if (data.code === 0) {
              this.refresh();
              return this.$message.success('退回成功!');
            } else {
              let mg = data.msg;
              let tp = 'error';
              return this.$message.error({ message: mg, type: tp });
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
