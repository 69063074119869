import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '单据号', prop: 'oinv_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '发票日期',
    prop: 'oinv_date',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '开票供应商', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '发票金额', prop: 'oinv_invtotal', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '收票日期',
    prop: 'oinv_recdate',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '付款日期',
    prop: 'oinv_paydate',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '部门', prop: 'oinv_dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '公司抬头', prop: 'oinv_cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '本币金额', prop: 'scon_rmbtotal', itemType: 'input', input: false, sortable: false, widthAuto: true },
  store.state.stff_name,
  store.state.create_time,
  store.state.status
];
