import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const oinvshareAPI = {
  addOinvShare: params => postNoCatch(`${topic.oinvshareTopic}/addOinvShare`, params),
  getOinvShareList: topic.oinvshareTopic + '/getOinvShareList',
  getOinvShareById: topic.oinvshareTopic + '/getOinvShareById',
  editOinvShare: topic.oinvshareTopic + '/editOinvShare',
  backOinv: params => postNoCatch(`${topic.oinvshareTopic}/backOinv`, params)
};
